import { Injectable } from '@angular/core';
import { filter, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class IonicEventService {
  private readonly openCloseSource = new Subject<boolean>();
  public readonly open$ = this.openCloseSource.pipe(filter(opened => opened));
  public readonly close$ = this.openCloseSource.pipe(filter(opened => !opened));

  public open(): void {
    this.openCloseSource.next(true);
  }

  public close(): void {
    this.openCloseSource.next(false);
  }
}
